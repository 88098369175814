/** @jsx jsx */
import { Flex } from "@theme-ui/components"
import { Link } from "gatsby"
import { Heading, jsx, Link as TLink } from "theme-ui"
import useBlogConfig from "../hooks/use-blog-config"
import replaceSlashes from "../utils/replaceSlashes"
import Layout from "./layout"
import Listing from "./listing"
import SEO from "./seo"

const TagComponent = ({ posts, pageContext }) => {
  const { tagsPath, basePath } = useBlogConfig()

  return (
    <Layout>
      <SEO title={`Tag: ${pageContext.name}`} />
      <Flex
        sx={{
          alignItems: `center`,
          justifyContent: `space-between`,
          flexFlow: `wrap`,
        }}
      >
        <Heading variant="styles.h2">{pageContext.name}</Heading>
        <TLink
          as={Link}
          sx={{ variant: `links.secondary` }}
          to={replaceSlashes(`/${basePath}/${tagsPath}`)}
        >
          View all tags
        </TLink>
      </Flex>
      <Listing posts={posts} sx={{ mt: [4, 5] }} />
    </Layout>
  )
}

const Tag = ({ data, pageContext }) => {
  const { allPost } = data

  return <TagComponent posts={allPost.nodes} pageContext={pageContext} />
}

export default Tag
